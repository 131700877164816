<template>
  <div>
    <b-row>
      <b-col
        xl="4"
        lg="5"
        md="6"
        sm="12"
      >
        <b-card>
          <b-form>
            <validation-observer
              ref="changePasswordRules"
              v-slot="{ invalid }"
            >
              <b-form-group
                label="Contraseña actual"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contraseña actual"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentPassword"
                    type="password"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Nueva contraseña"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nueva contraseña"
                  rules="required|min:6"
                  vid="newPwd"
                >
                  <input
                    v-model="newPassword"
                    name="newPwd"
                    class="form-control"
                    type="password"
                    required
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Confirmar nueva contraseña"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar nueva contraseña"
                  rules="required|confirmed:newPwd"
                >
                  <b-form-input
                    v-model="newPasswordConfirmation"
                    type="password"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </validation-observer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, confirmed, min } from '@validations'

import auth from '@/services/auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      saving: false,
      required,
      confirmed,
      min,
    }
  },
  methods: {
    async onSaveClick() {
      this.saving = true
      try {
        await auth.changePassword(this.currentPassword, this.newPassword)
        this.showNotification('Listo', 'Se actualizó su contraseña', 'CheckIcon', 'success')
        this.$router.back()
      } catch (err) {
        this.showNotification('Error al actualizar la contraseña', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.saving = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
